import { FaUsers, FaHome, FaBriefcase } from 'react-icons/fa';

function KeyServices() {
  return (
    <div className="key-services-container mt-16 text-center">
      <h2 className="text-3xl font-bold mb-10 text-[#FFFFFC]">KEY SERVICES</h2>
      <div className="flex flex-col md:flex-row justify-center gap-8">
        {/* Card 1: Personal & Family Documents */}
        <div className="service-card bg-[#212a37] p-6 rounded-lg shadow-lg w-full md:w-1/3">
          <FaUsers className="text-[#FFFFFC] text-4xl mx-auto mb-4" />
          <h3 className="text-2xl font-semibold text-[#FFFFFC]">Wills & Estate Planning Documents</h3>
          <p className="text-[#FFFFFC] mt-2">
            Comprehensive will drafting and estate planning to protect your family's legacy.
          </p>
        </div>

        {/* Card 2: Real Estate & Property Transfers */}
        <div className="service-card bg-[#212a37] p-6 rounded-lg shadow-lg w-full md:w-1/3">
          <FaHome className="text-[#FFFFFC] text-4xl mx-auto mb-4" />
          <h3 className="text-2xl font-semibold text-[#FFFFFC]">Real Estate & Property Transfers</h3>
          <p className="text-[#FFFFFC] mt-2">
            Reliable notarization for property transactions, title transfers, and deeds.
          </p>
        </div>

        {/* Card 3: Business & Legal Certifications */}
        <div className="service-card bg-[#212a37] p-6 rounded-lg shadow-lg w-full md:w-1/3">
          <FaBriefcase className="text-[#FFFFFC] text-4xl mx-auto mb-4" />
          <h3 className="text-2xl font-semibold text-[#FFFFFC]">Business & Legal Certifications</h3>
          <p className="text-[#FFFFFC] mt-2">
            Professional notarization for business documents, contracts, and legal certifications.
          </p>
        </div>
      </div>
    </div>
  );
}

export default KeyServices;
