// src/components/common/cookieconsent.tsx

import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

// Define google consent types for better type safety
type ConsentStatus = 'granted' | 'denied';
interface ConsentSettings {
  ad_storage: ConsentStatus;
  analytics_storage: ConsentStatus;
  functionality_storage: ConsentStatus;
  personalization_storage: ConsentStatus;
  security_storage: ConsentStatus;
  ad_user_data: ConsentStatus;
  ad_personalization: ConsentStatus;
}

function isProductionDomain() {
  return window.location.hostname.endsWith('.fortenotary.com') || window.location.hostname === 'fortenotary.com';
}

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  // Check if we're on any mobile notary page
  const isMobileNotaryPage = location.pathname.includes('/mobile-notary/');

  // Initialize GTM with default consent settings
  useEffect(() => {
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];

    // Insert GTM script (but with consent mode configuration first)
    if (isProductionDomain() && !document.getElementById('gtm-script')) {
      // 1. Add GTM script with consent mode configuration
      const script = document.createElement('script');
      script.id = 'gtm-script';
      script.innerHTML = `
        // Initialize dataLayer with consent configuration
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        
        // Set default consent state (denied for everything except security) for EU and US-CA regions
        // where banner is required
        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied',
          'functionality_storage': 'denied',
          'personalization_storage': 'denied',
          'security_storage': 'granted',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'wait_for_update': 100,
          'region': ['EU', 'US-CA']
        });

        gtag('consent', 'default', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted',
          'functionality_storage': 'granted',
          'personalization_storage': 'granted',
          'security_storage': 'granted',
          'ad_user_data': 'granted',
          'ad_personalization': 'granted',
          'wait_for_update': 100,
        });

        
        // Now load GTM normally
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PJCMZVZT');
      `;
      document.head.appendChild(script);

      // 2. Add GTM noscript element
      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJCMZVZT"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.insertAdjacentElement('afterbegin', noscript);
    }

    // Handle saved consent and mobile notary pages
    const consent = localStorage.getItem('cookiesAccepted');

    // Auto-grant for mobile notary pages
    if (isMobileNotaryPage) {
      if (!consent) {
        localStorage.setItem('cookiesAccepted', 'true');
      }
      updateConsentState(true);
      return;
    }

    // Normal behavior for other pages
    if (!consent) {
      setIsVisible(true);
    } else {
      updateConsentState(consent === 'true');
    }
  }, [isMobileNotaryPage]);

  // Update consent state based on user choice
  const updateConsentState = (accepted: boolean) => {
    const consentStatus: ConsentSettings = {
      ad_storage: accepted ? 'granted' : 'denied',
      analytics_storage: accepted ? 'granted' : 'denied',
      functionality_storage: accepted ? 'granted' : 'denied',
      personalization_storage: accepted ? 'granted' : 'denied',
      security_storage: 'granted', // Always grant security storage
      ad_user_data: accepted ? 'granted' : 'denied',
      ad_personalization: accepted ? 'granted' : 'denied'
    };

    if (typeof window !== 'undefined' && window.dataLayer) {

      // First, update using gtag which is specifically needed for consent mode
      if (window.gtag) {
        window.gtag('consent', 'update', consentStatus);
      }

      // Then, update using dataLayer.push. The event will trigger GTM to send the updated consent to GA
      window.dataLayer.push({
        event: 'consent_update',
        ...consentStatus
      });


    } else {
      // console.log('dataLayer not found. Unable to update consent.');
      // No operation performed
    }
  };

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
    updateConsentState(true);
  };

  const rejectCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setIsVisible(false);
    updateConsentState(false);
  };

  // Don't render the component at all for any mobile notary page
  if (isMobileNotaryPage) {
    return null;
  }

  return (
    <motion.div
      initial={{ y: '100%' }}
      animate={{ y: isVisible ? 0 : '100%' }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className="fixed bottom-0 left-0 right-0 p-4 bg-[#2A3445] text-white flex justify-between items-center z-50 shadow-lg"
    >
      {/* Banner content - unchanged */}
      <p className="text-sm">
        We use cookies to improve your Website experience, provide additional security, track user behavior, enable personalized advertising, and remember you when you return to the Website. By continuing to use this Website, you agree to the use of cookies. See our
        <a href="/privacy-policy" className="underline ml-2">
          Privacy Policy
        </a>
        .
      </p>
      <div className="flex">
        <button
          className="bg-gray-600 text-white px-4 py-2 rounded mr-2"
          onClick={rejectCookies}
        >
          Decline
        </button>
        <button
          className="bg-[#4CAF50] text-white px-4 py-2 ml-2 rounded"
          onClick={acceptCookies}
        >
          Accept All
        </button>
      </div>
    </motion.div>
  );
};

// TypeScript declaration
declare global {
  interface Window {
    gtag: (command: string, action: string, params?: any) => void;
    dataLayer: any[];
  }
}

export default CookieConsent;