import React from 'react';
import SEO from '../components/common/seo/SEO';
import Breadcrumbs from '@/components/common/seo/Breadcrumbs';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull';
import { css } from '@emotion/css';

const sectionHeaderStyle = css`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1.5rem 0 0.75rem 0;
  color: #e2ba57;
  border-bottom: 1px solid #e2ba57;
  padding-bottom: 0.5rem;
  display: inline-block;
`;

const subHeaderStyle = css`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1.25rem 0 0.5rem 0;
  color: #ffa34d;
`;

const paragraphStyle = css`
  margin: 0.75rem 0;
  line-height: 1.6;
`;

const listStyle = css`
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  
  li {
    margin-bottom: 0.5rem;
    line-height: 1.6;
  }
`;

const tableOfContentsStyle = css`
  background-color: #2b3645;
  padding: 1rem;
  border-radius: 5px;
  margin: 1.5rem 0;
  
  ol {
    margin-left: 1.5rem;
    list-style-type: decimal;
    
    li {
      margin-bottom: 0.5rem;
      
      a {
        color: #e2ba57;
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const contentContainerStyle = css`
  max-width: 800px;
  width: 100%;
  padding: 2rem;
  background-color: #212A37;
  border-radius: 5px;
  margin-top: 2rem;
`;

const noticeStyle = css`
  background-color: #2b3645;
  padding: 1rem;
  border-left: 4px solid #e2ba57;
  margin: 1rem 0;
  font-weight: bold;
`;

const PrivacyPolicy = () => {
  const breadcrumbs = [
    { name: 'Main', url: '/' },
    { name: 'Privacy Policy', url: '/privacy-policy' }
  ];

  return (
    <>
      <SEO
        title='Privacy Policy | Forte Notary'
        description="Read Forte Notary's Privacy Policy to understand how we collect, use, and protect your personal information when providing our mobile notary services."
        includeOrg={false}
        canonicalUrl='https://fortenotary.com/privacy-policy'
      />
      <div className="bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col">
        {/* Breadcrumbs section */}
        <Breadcrumbs url="https://fortenotary.com" breadcrumbs={breadcrumbs} />

        {/* Header Section */}
        <header className="fixed top-0 left-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/85 backdrop-blur z-[100]">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <AppHeaderMobile />
          </div>
        </header>

        {/* Spacer to offset fixed header height */}
        <div className="h-24 md:h-[140px]"></div>

        {/* Main Content Section */}
        <main className="flex-grow flex justify-center px-4">
          <div className={contentContainerStyle}>
            <h1 className="text-3xl font-bold mb-6 text-[#e2ba57]">Privacy Policy</h1>
            <p className={paragraphStyle}>
              Last updated: March 17, 2025
            </p>
            <p className={paragraphStyle}>
              Thank you for choosing Forte Notary. We respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website or our mobile notary services.
            </p>



            {/* Table of Contents */}
            <div className={tableOfContentsStyle}>
              <h2 className="text-xl font-bold mb-2 text-[#e2ba57]">Table of Contents</h2>
              <ol>
                <li><a href="#information-collected">Information We Collect</a></li>
                <li><a href="#how-we-collect">How We Collect Your Information</a></li>
                <li><a href="#use-of-information">How We Use Your Information</a></li>
                <li><a href="#disclosure">Disclosure of Your Information</a></li>
                <li><a href="#sms-messaging">SMS and Text Messaging</a></li>
                <li><a href="#tracking">Cookies and Tracking Technologies</a></li>
                <li><a href="#third-party">Third-Party Websites</a></li>
                <li><a href="#data-security">Data Security</a></li>
                <li><a href="#data-retention">Data Retention</a></li>
                <li><a href="#your-rights">Your Privacy Rights</a></li>
                <li><a href="#california">California Privacy Rights</a></li>
                <li><a href="#children">Children's Information</a></li>
                <li><a href="#updates">Updates to This Privacy Policy</a></li>
                <li><a href="#contact">Contact Us</a></li>
              </ol>
            </div>

            {/* Information We Collect */}
            <h2 id="information-collected" className={sectionHeaderStyle}>1. Information We Collect</h2>
            <p className={paragraphStyle}>
              We may collect several types of information from and about users of our website and services, including:
            </p>
            <ul className={listStyle}>
              <li><strong>Personal Identifiers:</strong> Name, postal address, email address, telephone number, date of birth, and government-issued identification information.</li>
              <li><strong>Service Data:</strong> Information about notarizations and other services requested, appointment details, and documents processed.</li>
              <li><strong>Payment Information:</strong> Credit card numbers, billing addresses, and other payment details (note that payment processing is handled by our secure third-party payment processors).</li>
              <li><strong>Location Data:</strong> Geographic location when you request mobile notary services.</li>
              <li><strong>Technical Data:</strong> Internet protocol (IP) address, browser type, operating system, device information, browsing actions, patterns, page visits, interaction data, and usage statistics.</li>
              <li><strong>Marketing Data:</strong> Your preferences in receiving marketing communications from us.</li>
              <li><strong>Communication Data:</strong> Contact information including phone numbers and communication preferences for scheduling, providing directions, requesting feedback, and future follow-up regarding our services.</li>
              <li><strong>Text Messaging Data:</strong> Information related to your opt-in consent for receiving text messages, including phone numbers, timestamps, and consent records.</li>
            </ul>



            {/* How We Collect */}
            <h2 id="how-we-collect" className={sectionHeaderStyle}>2. How We Collect Your Information</h2>
            <p className={paragraphStyle}>
              We collect information through:
            </p>
            <ul className={listStyle}>
              <li><strong>Direct Interactions:</strong> Information you provide when filling out forms, scheduling appointments, completing transactions, or communicating with us.</li>
              <li><strong>Phone and Text Communications:</strong> Information you provide when contacting us via phone or SMS text message, including contact details necessary for providing mobile notary services.</li>
              <li><strong>Automated Technologies:</strong> As you navigate our website, we may use cookies, server logs, custom JavaScript tracking, and similar technologies to collect technical data about your equipment, browsing actions, and patterns. This includes our custom tracking script that logs website activity to Google BigQuery for analysis.</li>
              <li><strong>Third Parties:</strong> We may receive information about you from third parties including business partners, service providers, and publicly available sources.</li>
            </ul>

            {/* Use of Information */}
            <h2 id="use-of-information" className={sectionHeaderStyle}>3. How We Use Your Information</h2>
            <p className={paragraphStyle}>
              We use your information for the following purposes:
            </p>
            <ul className={listStyle}>
              <li>To provide and improve our mobile notary services</li>
              <li>To process and complete transactions</li>
              <li>To schedule and manage appointments</li>
              <li>To communicate with you about our services</li>
              <li>To provide directions to service locations</li>
              <li>To respond to your inquiries and customer service requests</li>
              <li>To send you service-related notifications</li>
              <li>To maintain legally required records of notarial acts</li>
              <li>To verify your identity when required for notarial acts</li>
              <li>To comply with legal and regulatory requirements</li>
              <li>To analyze usage trends and improve our website and services</li>
              <li>To protect against fraudulent, unauthorized, or illegal activity</li>
              <li>To request feedback about our services</li>
              <li>To maintain contact information for future service opportunities</li>
              <li>For marketing and promotional purposes (with your consent)</li>
            </ul>

            {/* Disclosure */}
            <h2 id="disclosure" className={sectionHeaderStyle}>4. Disclosure of Your Information</h2>
            <p className={paragraphStyle}>
              We may disclose your personal information to:
            </p>
            <ul className={listStyle}>
              <li><strong>Service Providers:</strong> Third parties that perform services on our behalf, such as payment processing, appointment scheduling, and marketing assistance.</li>
              <li><strong>Business Partners:</strong> Trusted third parties with whom we collaborate to offer certain services or promotions.</li>
              <li><strong>Legal Authorities:</strong> Government agencies, law enforcement, courts, or other parties when required by law or in response to legal processes or requests.</li>
              <li><strong>Professional Advisors:</strong> Accountants, attorneys, consultants, and other professionals as necessary.</li>
              <li><strong>Business Transfers:</strong> In connection with a merger, acquisition, reorganization, sale of assets, or bankruptcy, your information may be transferred to another company.</li>
            </ul>
            <p className={paragraphStyle}>
              We do not sell your personal information to third parties.
            </p>

            <div className={noticeStyle}>
              All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
            </div>

            {/* SMS and Text Messaging */}
            <h2 id="sms-messaging" className={sectionHeaderStyle}>5. SMS and Text Messaging</h2>
            <p className={paragraphStyle}>
              As part of our notary services, we may communicate with you via text messages for service-related purposes such as scheduling appointments, providing directions, confirming details, and requesting feedback.
            </p>
            <h3 className={subHeaderStyle}>Text Message Consent:</h3>
            <p className={paragraphStyle}>
              By providing your mobile phone number and consenting to receive text messages, you agree to receive SMS or MMS communications from Forte Notary related to our services. Message and data rates may apply. You can opt out of receiving text messages at any time by replying "STOP" to any of our messages.
            </p>
            <h3 className={subHeaderStyle}>SMS Data Handling:</h3>
            <p className={paragraphStyle}>
              Forte Notary may disclose Personal Data and other information as follows:
            </p>
            <ul className={listStyle}>
              <li><strong>Third Parties that Help Provide the Messaging Service:</strong> We will not share your opt-in to an SMS short code campaign with a third party for purposes unrelated to supporting you in connection with that campaign. We may share your Personal Data with third parties that help us provide the messaging service, including, but not limited to, platform providers, phone companies, and other vendors who assist us in the delivery of text messages.</li>
              <li><strong>Additional Disclosures:</strong> Affiliates: We may disclose the Personal Data to our affiliates or subsidiaries; however, if we do so, their use and disclosure of your Personal Data will be subject to this Policy. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.</li>
            </ul>
            <p className={paragraphStyle}>
              We use OpenPhone as our text messaging service provider to send and receive messages. While OpenPhone has access to message content and phone numbers as necessary to provide their service, we maintain strict confidentiality of your text messaging data.
            </p>

            {/* Cookies and Tracking */}
            <h2 id="tracking" className={sectionHeaderStyle}>6. Cookies and Tracking Technologies</h2>
            <p className={paragraphStyle}>
              We use cookies and similar tracking technologies to track activity on our website and to hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier.
            </p>
            <h3 className={subHeaderStyle}>Types of Cookies We Use:</h3>
            <ul className={listStyle}>
              <li><strong>Essential Cookies:</strong> Required for the operation of our website.</li>
              <li><strong>Analytical/Performance Cookies:</strong> Allow us to recognize and count visitors and see how they move around our website.</li>
              <li><strong>Functionality Cookies:</strong> Enable our website to remember your preferences and settings.</li>
              <li><strong>Targeting Cookies:</strong> Record your visit to our website, the pages you visit, and the links you follow to make our advertising more relevant to your interests.</li>
            </ul>
            <p className={paragraphStyle}>
              We use Google Analytics and Google Tag Manager to analyze the use of our website. Google Analytics gathers information about website use by means of cookies. The information gathered is used to create reports about the use of our website.
            </p>
            <h3 className={subHeaderStyle}>Custom Tracking Technologies:</h3>
            <p className={paragraphStyle}>
              Our website employs custom JavaScript that logs user traffic data and interactions. This information is transmitted to Google BigQuery for storage and analysis. The data collected may include the pages you visit, your actions on those pages, timestamps of your visit, and technical information about your device. We use this information to improve our website, analyze usage patterns, optimize our services, and enhance your user experience.
            </p>
            <p className={paragraphStyle}>
              You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our website may become inaccessible or not function properly.
            </p>

            {/* Third-Party Websites */}
            <h2 id="third-party" className={sectionHeaderStyle}>7. Third-Party Websites</h2>
            <p className={paragraphStyle}>
              Our website may contain links to third-party websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. We encourage you to read the privacy policy of every website you visit.
            </p>

            {/* Data Security */}
            <h2 id="data-security" className={sectionHeaderStyle}>8. Data Security</h2>
            <p className={paragraphStyle}>
              We have implemented appropriate security measures to prevent your personal information from being accidentally lost, used, accessed, altered, or disclosed in an unauthorized way. We limit access to your personal information to those employees, agents, contractors, and other third parties who have a business need to know.
            </p>
            <p className={paragraphStyle}>
              While we strive to use commercially acceptable means to protect your personal information, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>

            {/* Data Retention */}
            <h2 id="data-retention" className={sectionHeaderStyle}>9. Data Retention</h2>
            <p className={paragraphStyle}>
              We will retain your personal information only for as long as reasonably necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, regulatory, tax, accounting, or reporting requirements.
            </p>
            <p className={paragraphStyle}>
              For notarial records, we retain information as required by Texas law and notarial regulations, which may require retention of certain records for a specific period of time after the notarial act is performed.
            </p>
            <p className={paragraphStyle}>
              Contact information collected during our service interactions may be retained for future follow-up, communication about new services, and to facilitate service for returning customers. You may request deletion of this information as outlined in the "Your Privacy Rights" section.
            </p>

            {/* Your Rights */}
            <h2 id="your-rights" className={sectionHeaderStyle}>10. Your Privacy Rights</h2>
            <p className={paragraphStyle}>
              Depending on your location, you may have certain rights regarding your personal information. These may include:
            </p>
            <ul className={listStyle}>
              <li>The right to access the personal information we hold about you</li>
              <li>The right to request correction of your personal information</li>
              <li>The right to request deletion of your personal information</li>
              <li>The right to object to processing of your personal information</li>
              <li>The right to request restriction of processing your personal information</li>
              <li>The right to data portability</li>
              <li>The right to withdraw consent</li>
            </ul>
            <p className={paragraphStyle}>
              To exercise any of these rights, please contact us using the details provided in the "Contact Us" section below.
            </p>

            {/* California Privacy Rights */}
            <h2 id="california" className={sectionHeaderStyle}>11. California Privacy Rights</h2>
            <p className={paragraphStyle}>
              If you are a California resident, you have specific rights regarding your personal information under the California Consumer Privacy Act (CCPA). These include:
            </p>
            <ul className={listStyle}>
              <li>The right to know about personal information collected, disclosed, or sold</li>
              <li>The right to request deletion of personal information</li>
              <li>The right to opt-out of the sale of personal information</li>
              <li>The right to non-discrimination for exercising your CCPA rights</li>
            </ul>
            <p className={paragraphStyle}>
              We do not sell personal information. To exercise your rights under the CCPA, please contact us using the information provided in the "Contact Us" section.
            </p>

            {/* Children's Information */}
            <h2 id="children" className={sectionHeaderStyle}>12. Children's Information</h2>
            <p className={paragraphStyle}>
              Our services are not directed to children under 16 years of age, and we do not knowingly collect personal information from children under 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information.
            </p>

            {/* Updates */}
            <h2 id="updates" className={sectionHeaderStyle}>13. Updates to This Privacy Policy</h2>
            <p className={paragraphStyle}>
              We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated policy will be posted on this page with a revised "Last updated" date. We encourage you to review this Privacy Policy regularly to stay informed about how we are protecting your information.
            </p>
            <p className={paragraphStyle}>
              Your continued use of our website or services following the posting of changes constitutes your acceptance of such changes.
            </p>

            {/* Contact */}
            <h2 id="contact" className={sectionHeaderStyle}>14. Contact Us</h2>
            <p className={paragraphStyle}>
              If you have questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at:
            </p>
            <p className={paragraphStyle}>
              Email: <a href="mailto:ralph.brooks@fortenotary.com" className="text-[#ffa34d] hover:underline">ralph.brooks@fortenotary.com</a><br />
              Phone: <a href="tel:4692968682" className="text-[#ffa34d] hover:underline">(469) 296-8682</a><br />
              Address: 5013 Madison Drive, Frisco, TX 75036
            </p>
            <p className={paragraphStyle}>
              We will respond to your request as soon as reasonably practicable and in accordance with applicable laws.
            </p>

          </div>
        </main>
      </div>
    </>
  );
};

export default PrivacyPolicy;