
export interface SchemaOrgType {
  "@context": string;
  "@type": string;
  name: string;
  url: string;
  telephone: string;
  address: {
    "@type": string;
    streetAddress: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    addressCountry: string;
  };
  areaServed: string[]; // Array of areas served
  openingHours: string; // Opening hours in ISO format
  sameAs?: string[]; // Optional array of social or related links
  paymentAccepted?: string; // Optional payment methods accepted
  currenciesAccepted?: string; // Optional accepted currency
  aggregateRating?: {
    "@type": string;
    ratingValue: string;
    reviewCount: string;
  }; // Optional aggregate rating
}


// src/components/common/seo/schemaOrg.ts
import areaServed from './areaServed';

const schemaOrgJSONLD = {
  "@context": "https://schema.org",
  "@type": "Notary",
  "name": "Forte Notary",
  "telephone": "+1-469-296-8682", // Update with your actual phone number
  "url": "https://fortenotary.com",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "5013 Madison Drive", // Update with your actual address
    "addressLocality": "Frisco",
    "addressRegion": "TX",
    "postalCode": "75035",
    "addressCountry": "US"
  },
  // areaServed needs to be consistent with what is shown in Google Business Profile
  "areaServed": areaServed,
  "openingHours": "Mo-Su 07:00-23:00",
  "sameAs": [
    "https://www.facebook.com/people/Forte-Notary/61571141803077/"
  ],
  "currenciesAccepted": "USD",
  "paymentAccepted": "Cash, Credit Card",
  "acceptedPaymentMethod": [
    "http://purl.org/goodrelations/v1#VISA",
    "http://purl.org/goodrelations/v1#MasterCard"
  ],
  // aggregateRating is needed for the stars on ralphbrooks.tsx
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5.0",
    "reviewCount": "33",
  },
};

export default schemaOrgJSONLD;
