import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import SEO from '../components/common/seo/SEO';
import Breadcrumbs from '@/components/common/seo/Breadcrumbs';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull';

// Font styles
const featureDisplayStyle = css`
  font-family: 'FeatureDisplay', sans-serif;
`;

const featureDeckStyle = css`
  font-family: 'FeatureDeck', sans-serif;
`;

const featureDeckBold = css`
  font-family: 'FeatureDeck', sans-serif;
  font-weight: 700;
`;

const sohneStyle = css`
  font-family: 'Sohne', sans-serif;
`;

// Colors
const colors = {
  deepOlive: '#51624f',
  darkerOlive: '#384437',
  lightBeige: '#f5f2ec',
  lightTan: '#e5c495',
  darkerTan: '#a18a69',
  offWhite: '#F5F2EC',
  accentOrange: '#ffa34d',
  buttonOrange: '#DB8C42',
  darkBlue: '#212A37',
  mediumBlue: '#2b3645',
  gold: '#e2ba57'
};

// Critical CSS
const criticalCss = `
  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    font-family: 'Sohne', sans-serif;
    background-color: ${colors.darkBlue};
    color: ${colors.offWhite};
    overflow-x: hidden;
  }
  
  @font-face {
    font-family: 'FeatureDisplay';
    src: url('/fonts/FeatureDisplay-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'FeatureDeck';
    src: url('/fonts/FeatureDeck-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'FeatureDeck';
    src: url('/fonts/FeatureDeck-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Sohne';
    src: url('/fonts/Sohne-Buch.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 40px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .fadeInUp {
    animation: fadeInUp 0.8s ease-out forwards;
  }
  
  .fadeIn {
    animation: fadeIn 1s ease-out forwards;
  }
  
  .scaleIn {
    animation: scaleIn 0.8s ease-out forwards;
  }
  
  .pulse {
    animation: pulse 4s ease-in-out infinite;
  }
`;

// Component Styles
const pageContainer = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${colors.darkBlue};
  color: ${colors.offWhite};
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background: url('/mobile-notary/frisco/2025-01-31-1431/pictures/coffee-shop.webp') right center/cover no-repeat;
    opacity: 0.05;
    z-index: 0;
  }
`;

const mainSection = css`
  position: relative;
  z-index: 1;
  padding: 2rem 1rem;
  margin-top: 5rem;
  
  @media (min-width: 768px) {
    padding: 4rem 2rem;
    margin-top: 6rem;
  }
`;

const contentContainer = css`
  max-width: 1400px;
  margin: 0 auto;
`;

const headerSection = css`
  max-width: 1000px;
  margin: 0 auto 4rem;
  text-align: center;
  position: relative;
`;

const pageTitle = css`
  ${featureDisplayStyle}
  font-size: 3.5rem;
  color: ${colors.gold};
  margin-bottom: 1.5rem;
  position: relative;
  
  &::after {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    height: 3px;
    background: linear-gradient(to right, ${colors.accentOrange}, ${colors.gold});
  }
  
  @media (min-width: 768px) {
    font-size: 5rem;
  }
`;

const pageSubtitle = css`
  ${featureDeckStyle}
  font-size: 1.5rem;
  color: ${colors.offWhite};
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
  
  @media (min-width: 768px) {
    font-size: 1.75rem;
  }
`;

const blogGridContainer = css`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  
  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
  
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const blogCard = css`
  background-color: ${colors.mediumBlue};
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  }
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, ${colors.accentOrange}, ${colors.gold});
  }
`;

const blogImageWrapper = css`
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 50%, rgba(33, 42, 55, 0.8) 100%);
    z-index: 1;
  }
`;

const blogImage = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
  
  ${blogCard}:hover & {
    transform: scale(1.05);
  }
`;

const blogCardContent = css`
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const blogCategory = css`
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: ${colors.darkerOlive};
  color: ${colors.offWhite};
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
  border-radius: 4px;
`;

const blogTitle = css`
  ${featureDeckBold}
  font-size: 1.75rem;
  color: ${colors.gold};
  margin-bottom: 1rem;
  line-height: 1.3;
  
  a {
    color: inherit;
    text-decoration: none;
    position: relative;
    display: inline-block;
    
    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: ${colors.accentOrange};
      transition: width 0.3s ease;
    }
    
    &:hover::after {
      width: 100%;
    }
  }
`;

const blogExcerpt = css`
  ${sohneStyle}
  font-size: 1.125rem;
  color: ${colors.offWhite};
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const blogMeta = css`
  ${sohneStyle}
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  font-size: 0.875rem;
  color: ${colors.lightTan};
`;

const blogDate = css`
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
`;

const readMoreLink = css`
  ${featureDeckBold}
  display: inline-flex;
  align-items: center;
  color: ${colors.accentOrange};
  text-decoration: none;
  transition: color 0.3s ease;
  
  svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }
  
  &:hover {
    color: ${colors.gold};
    
    svg {
      transform: translateX(3px);
    }
  }
`;

const newsletterSection = css`
  background: linear-gradient(135deg, ${colors.deepOlive} 0%, ${colors.darkerOlive} 100%);
  padding: 4rem 2rem;
  margin-top: 6rem;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/mobile-notary/frisco/2025-01-31-1431/pictures/coffee-shop.webp') center/cover no-repeat;
    opacity: 0.1;
    z-index: 0;
  }
`;

const newsletterContainer = css`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  text-align: center;
`;

const newsletterTitle = css`
  ${featureDeckBold}
  font-size: 2.5rem;
  color: ${colors.offWhite};
  margin-bottom: 1.5rem;
`;

const newsletterText = css`
  ${sohneStyle}
  font-size: 1.25rem;
  color: ${colors.offWhite};
  margin-bottom: 2.5rem;
  line-height: 1.6;
  opacity: 0.9;
`;

const newsletterForm = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
  
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const newsletterInput = css`
  flex: 1;
  padding: 1.25rem;
  border: 1px solid rgba(226, 186, 87, 0.5);
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Sohne', sans-serif;
  background-color: rgba(245, 242, 236, 0.92);
  color: ${colors.darkBlue};
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  
  &::placeholder {
    color: rgba(56, 68, 55, 0.6);
  }
  
  &:focus {
    outline: none;
    background-color: rgba(255, 250, 240, 0.95);
    border-color: ${colors.accentOrange};
    box-shadow: 0 0 0 2px rgba(219, 140, 66, 0.4), inset 0 2px 4px rgba(0, 0, 0, 0.05);
    color: ${colors.darkerOlive};
  }
`;

const ctaButton = css`
  ${featureDeckBold}
  display: inline-block;
  background: linear-gradient(to right, ${colors.accentOrange}, ${colors.buttonOrange});
  color: white;
  font-size: 1.125rem;
  padding: 1.25rem 2rem;
  border-radius: 4px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background: linear-gradient(to right, ${colors.buttonOrange}, ${colors.accentOrange});
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(219, 140, 66, 0.4);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const statusMessage = css`
  margin-top: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease-out;
  animation: scaleIn 0.5s ease-out forwards;
  
  &.success {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(245, 242, 236, 0.95));
    color: ${colors.darkerOlive};
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
    border-left: 5px solid ${colors.gold};
    padding: 1.5rem;
  }
  
  &.error {
    background-color: rgba(211, 47, 47, 0.9);
    color: white;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    padding: 1.25rem;
  }
`;

const successMessageIcon = css`
  width: 52px;
  height: 52px;
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(135deg, ${colors.accentOrange}, ${colors.gold});
  color: white;
  box-shadow: 0 5px 15px rgba(226, 186, 87, 0.4);
`;

const successMessageTitle = css`
  ${featureDeckBold}
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  color: ${colors.darkerOlive};
`;

const successMessageText = css`
  ${sohneStyle}
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${colors.deepOlive};
  margin-bottom: 0.25rem;
`;


const CalendarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="4" width="18" height="18" rx="2" stroke="#e5c495" strokeWidth="2" />
    <path d="M16 2V5" stroke="#e5c495" strokeWidth="2" strokeLinecap="round" />
    <path d="M8 2V5" stroke="#e5c495" strokeWidth="2" strokeLinecap="round" />
    <path d="M3 10H21" stroke="#e5c495" strokeWidth="2" />
  </svg>
);

const ArrowRightIcon = () => (
  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M13 6L19 12L13 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CheckIcon = () => (
  <svg width="32" height="32" viewBox="0 0 24 24" fill="none" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
    <path d="M20 6L9 17L4 12" stroke="currentColor" />
  </svg>
);

const ErrorIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" style={{ marginRight: '0.5rem', verticalAlign: 'middle' }}>
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="rgba(211, 47, 47, 0.2)" stroke="#d32f2f" strokeWidth="2" />
    <path d="M12 8V13" stroke="#d32f2f" strokeWidth="2" strokeLinecap="round" />
    <path d="M12 16V16.5" stroke="#d32f2f" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const BlogList = () => {
  const breadcrumbs = [
    { name: 'Main', url: '/' },
    { name: 'Blog', url: '/blog' }
  ];

  const [animatedSections, setAnimatedSections] = useState({
    header: false,
    blogGrid: false,
    newsletter: false
  });

  const [formStatus, setFormStatus] = useState('idle'); // 'idle', 'loading', 'success', 'error'
  const [emailValue, setEmailValue] = useState('');

  // References for sections to observe
  const headerRef = useRef(null);
  const blogGridRef = useRef(null);
  const newsletterRef = useRef(null);

  useEffect(() => {
    // Set up intersection observers for each section
    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0px 0px -100px 0px'
    };

    const sectionRefs = [
      { ref: headerRef, id: 'header' },
      { ref: blogGridRef, id: 'blogGrid' },
      { ref: newsletterRef, id: 'newsletter' }
    ];

    const observers = sectionRefs.map(({ ref, id }) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setAnimatedSections(prev => ({ ...prev, [id]: true }));
            observer.disconnect();
          }
        });
      }, observerOptions);

      if (ref.current) {
        observer.observe(ref.current);
      }

      return observer;
    });

    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, []);

  const handleNewsletterSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormStatus('loading');

    const data = {
      name: 'unknown',
      phone: 'unknown',
      email: emailValue,
      message: `Blog: Newsletter inquiry: ${emailValue}`
    };

    try {
      const response = await fetch(
        'https://app-customer-form-emailer-460102063392.us-central1.run.app/submit_contact',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setFormStatus('success');
      setEmailValue('');
      setTimeout(() => setFormStatus('idle'), 5000);
    } catch (error) {
      console.error('Newsletter submission error:', error);
      setFormStatus('error');
      setTimeout(() => setFormStatus('idle'), 5000);
    }
  };

  return (
    <>
      {/* Critical CSS */}
      <style>{criticalCss}</style>

      <SEO
        title='Estate Planning & Notarization Insights | Forte Notary Blog'
        description="Discover actionable advice on wills, estate planning, vehicle transfers, and notarization best practices from Forte Notary's expert team."
        includeOrg={false}
        canonicalUrl='https://fortenotary.com/blog'
      />

      <div className={pageContainer}>
        <Breadcrumbs url="https://fortenotary.com" breadcrumbs={breadcrumbs} />

        {/* Header Section */}
        <header className="fixed top-0 left-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur z-[100]">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <AppHeaderMobile />
          </div>
        </header>

        {/* Main Content */}
        <main className={mainSection}>
          <div className={contentContainer}>
            {/* Header Section */}
            <div
              className={headerSection}
              ref={headerRef}
              style={{
                opacity: animatedSections.header ? 1 : 0,
                transform: animatedSections.header ? 'translateY(0)' : 'translateY(30px)',
                transition: 'opacity 0.6s ease-out, transform 0.6s ease-out'
              }}
            >
              <h1 className={pageTitle}>Insights & Expertise</h1>
              <p className={pageSubtitle}>
                Discover practical wisdom on estate planning, property transfers, and notarization essentials that protect what matters most to you.
              </p>
            </div>

            {/* Blog Grid */}
            <div
              className={blogGridContainer}
              ref={blogGridRef}
              style={{
                opacity: animatedSections.blogGrid ? 1 : 0,
                transform: animatedSections.blogGrid ? 'translateY(0)' : 'translateY(30px)',
                transition: 'opacity 0.6s ease-out, transform 0.6s ease-out'
              }}
            >
              {/* DIY Will Checklist Entry */}
              <div className={blogCard}>
                <div
                  className={blogImageWrapper}
                  style={{ backgroundColor: '#eae6de' }} // Force the background color with inline styles
                >
                  <img
                    src="/blog/diy-will-checklist/draft-will.webp"
                    alt="DIY Will Checklist - Forte Notary"
                    className={blogImage}
                    style={{
                      backgroundColor: '#eae6de',
                      objectFit: 'contain', // Changed from 'cover' to 'contain' to show background

                    }}
                  />
                </div>
                <div className={blogCardContent}>
                  <span className={blogCategory}>Wills & Estates</span>
                  <h2 className={blogTitle}>
                    <Link to="/blog/wills/diy-will-checklist">
                      The Ultimate DIY Will Checklist: Create a Valid Will Without Costly Mistakes
                    </Link>
                  </h2>
                  <p className={blogExcerpt}>
                    Stop procrastinating on your will! This step-by-step guide reveals the essential elements that make your DIY will legally sound in Texas.
                  </p>
                  <div className={blogMeta}>
                    <span className={blogDate}>
                      <CalendarIcon /> March 19, 2025
                    </span>
                    <Link to="/blog/wills/diy-will-checklist" className={readMoreLink}>
                      Read More <ArrowRightIcon />
                    </Link>
                  </div>
                </div>
              </div>

              {/* Vehicle Transfer Entry */}
              <div className={blogCard}>
                <div className={blogImageWrapper}>
                  <img
                    src="/mobile-notary/frisco/2025-01-31-1431/pictures/woman-escalade-1450-748.webp"
                    alt="Selling a Vehicle: Texas to Louisiana Transfer Guide"
                    className={blogImage}
                  />
                </div>
                <div className={blogCardContent}>
                  <span className={blogCategory}>Notary Essentials</span>
                  <h2 className={blogTitle}>
                    <Link to="/blog/notary-essentials/transfer-texas-vehicle-to-louisiana">
                      Selling Your Texas Vehicle? The Critical Document Most Sellers Forget
                    </Link>
                  </h2>
                  <p className={blogExcerpt}>
                    Avoid thousands in future liability! Discover the one document that protects Texas sellers when transferring vehicles to Louisiana buyers. Plus: the tax loophole that could save the buyer hundreds at registration.
                  </p>
                  <div className={blogMeta}>
                    <span className={blogDate}>
                      <CalendarIcon /> March 3, 2025
                    </span>
                    <Link to="/blog/notary-essentials/transfer-texas-vehicle-to-louisiana" className={readMoreLink}>
                      Read More <ArrowRightIcon />
                    </Link>
                  </div>
                </div>
              </div>

              {/* Estate Planning Witness Requirements Entry */}
              <div className={blogCard}>
                <div className={blogImageWrapper}>
                  <img
                    src="/mobile-notary/frisco/2025-01-31-1431/pictures/coffee-shop.webp"
                    alt="Estate Planning Witness Requirements"
                    className={blogImage}
                  />
                </div>
                <div className={blogCardContent}>
                  <span className={blogCategory}>Legal Insights</span>
                  <h2 className={blogTitle}>
                    <Link to="/blog/legal/estate-planning-witness-requirements">
                      The Witness Requirement That Invalidates 62% of Texas Wills
                    </Link>
                  </h2>
                  <p className={blogExcerpt}>
                    Attorney alert: This overlooked witness technicality is causing estate plans to fail probate. Our comprehensive guide reveals Texas-specific requirements for wills, MPOAs and FPOAs that every attorney must know.
                  </p>
                  <div className={blogMeta}>
                    <span className={blogDate}>
                      <CalendarIcon /> December 15, 2024
                    </span>
                    <Link to="/blog/legal/estate-planning-witness-requirements" className={readMoreLink}>
                      Read More <ArrowRightIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Newsletter Section */}
            <section
              className={newsletterSection}
              ref={newsletterRef}
              style={{
                opacity: animatedSections.newsletter ? 1 : 0,
                transform: animatedSections.newsletter ? 'translateY(0)' : 'translateY(30px)',
                transition: 'opacity 0.6s ease-out, transform 0.6s ease-out'
              }}
            >
              <div className={newsletterContainer}>
                <h2 className={newsletterTitle}>Get Estate Planning Updates</h2>
                <p className={newsletterText}>
                  Join our newsletter for practical advice on protecting your assets and loved ones. We'll send you timely notifications about law changes and expert tips.
                </p>
                <form className={newsletterForm} onSubmit={handleNewsletterSubmit}>
                  <input
                    type="email"
                    placeholder="Your email address"
                    className={newsletterInput}
                    value={emailValue}
                    onChange={(e) => setEmailValue(e.target.value)}
                    required
                    disabled={formStatus === 'loading'}
                  />
                  <button
                    type="submit"
                    className={ctaButton}
                    disabled={formStatus === 'loading'}
                  >
                    {formStatus === 'loading' ? 'Subscribing...' : 'Subscribe Now'}
                  </button>
                </form>

                {formStatus === 'success' && (
                  <div className={`${statusMessage} success`}>
                    <div className={successMessageIcon}>
                      <CheckIcon />
                    </div>
                    <h3 className={successMessageTitle}>Subscription Confirmed!</h3>
                    <p className={successMessageText}>
                      Thank you for joining our newsletter. You'll receive estate planning updates and valuable insights directly to your inbox.
                    </p>
                  </div>
                )}

                {formStatus === 'error' && (
                  <div className={`${statusMessage} error`}>
                    <ErrorIcon />
                    There was an error subscribing to the newsletter. Please try again later.
                  </div>
                )}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default BlogList;